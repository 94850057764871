<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M39.7109 25.171L39.7331 25.1487C39.7554 25.1265 39.7776 25.1042 39.7999 25.082C39.7999 25.082 39.7999 25.0598 39.8221 25.0598C39.8443 25.0375 39.8666 24.9931 39.8666 24.9708V24.9486C39.8888 24.9263 39.8888 24.8819 39.9111 24.8596C39.9111 24.8596 39.9111 24.8596 39.9111 24.8374C39.9333 24.7929 39.9333 24.7707 39.9555 24.7262C39.9555 24.7262 39.9555 24.7262 39.9555 24.704C39.9555 24.6595 39.9778 24.6372 39.9778 24.5928V24.5705C39.9778 24.5483 40 24.5038 40 24.4816C40 24.4371 40 24.3926 40 24.3482C40 24.3037 40 24.2592 40 24.2147C40 24.1925 40 24.148 39.9778 24.1258C39.9778 24.1035 39.9778 24.1035 39.9778 24.0813C39.9778 24.0368 39.9555 24.0146 39.9555 23.9701C39.9555 23.9701 39.9555 23.9701 39.9555 23.9479C39.9333 23.9034 39.9333 23.8812 39.9111 23.8367C39.9111 23.8367 39.9111 23.8367 39.9111 23.8145C39.8888 23.77 39.8888 23.7477 39.8666 23.7255V23.7033C39.8443 23.681 39.8221 23.6366 39.8221 23.6143C39.8221 23.6143 39.8221 23.5921 39.7999 23.5921C39.7776 23.5698 39.7554 23.5476 39.7331 23.5031L39.7109 23.4809C39.6887 23.4364 39.6442 23.4142 39.622 23.3919L27.6136 11.3836C27.1022 10.8721 26.2571 10.8721 25.7234 11.3836C25.212 11.8951 25.212 12.7401 25.7234 13.2738L35.4635 22.9917H9.33426C8.60042 22.9917 8 23.5921 8 24.3259C8 25.0598 8.60042 25.6602 9.33426 25.6602H35.4635L25.7234 35.4003C25.212 35.9117 25.212 36.7568 25.7234 37.2905C25.9903 37.5573 26.3238 37.6908 26.6574 37.6908C26.991 37.6908 27.3468 37.5573 27.5914 37.2905L39.5997 25.2821C39.6664 25.2377 39.6887 25.2154 39.7109 25.171Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-arrow-right-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
